import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  TextField } from '@mui/material'

export default function EmailVerify({ handleVerify, handleUpdate, history, username, disableSubmit }) {
  return(<form
    className="verify-form auth-form"
    method="post"
    onSubmit={event => {
      handleVerify(event)
    }}
  >
    <p>
      Please verify your acccount using the code sent to your e-mail.</p>
    <p>You should receive an e-mail from webmaster@forms-surfaces.net. Please add this address to your whitelist or safe senders list.</p>
    <FormControl>
    <FormLabel>Email</FormLabel>
    <Input
     id="username"
     name="username"
     value={username}
     placeholder="email@forms-surfaces.com"
     variant="outlined"
     readOnly={true}
    />
    </FormControl>
    <FormControl>
    <FormLabel>Verification Code</FormLabel>
    <TextField
     required
     id="code"
     name="code"
     defaultValue=""
     variant="outlined"
     onChange={handleUpdate}
    />
    </FormControl>
  </form>)
}
