import React from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  TextField } from '@mui/material'
import CountrySelect from './country-select'
const pronouns = [
  {
    value: '',
    label: 'prefer not to answer',
  },
  {
    value: 'she/her',
    label: 'she/her',
  },
  {
    value: 'he/him',
    label: 'he/him',
  },
  {
    value: 'they/them',
    label: 'they/them',
  },
  {
    value: 'other',
    label: 'other',
  }
];
export default function UserInfo({ handleSubmit, handleUpdate, userInfo, disableSubmit, handleCancel }) {
  return(
    <form
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
  <div className='row'>
    <div className='col-sm-6'>
        <FormControl>
        <FormLabel>First Name</FormLabel>
        <TextField
        className='Mui-focused'
        id="firstName"
        name="firstName"
        placeholder="First Name"
        variant="outlined"
        value={userInfo?.name?.first || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>Last Name</FormLabel>
      <TextField
        id="lastName"
        name="lastName"
        placeholder="Last Name"
        variant="outlined"
        value={userInfo?.name?.last || ''}
        onChange={handleUpdate}
      />
      </FormControl>
    </div>
  </div>
  <div className='row'>
    <div className='col-sm-6 hide-xs'>

    </div>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel id="pronouns-label" >
        Pronouns
        </FormLabel>
        <Select
        id="pronouns"
        name="pronouns"
        variant="outlined"
        sx={{width: '100%'}}
        value={userInfo?.pronouns || ''}
        onChange={handleUpdate}
        labelId="pronouns-label"
        >
        {pronouns.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
    </div>
  </div>
  <hr style={{margin: '2rem 0'}} />
  <div className='row'>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>Job Title</FormLabel>
      <TextField
          id="job_title"
          name="job_title"
          placeholder="Job Title"
          variant="outlined"
          value={userInfo?.job_title || ''}
          onChange={handleUpdate}
        />
        </FormControl>
    </div>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>Company</FormLabel>
      <TextField
        id="company"
        name="company"
        placeholder="Company"
        variant="outlined"
        value={userInfo?.company || ''}
        onChange={handleUpdate}
      />
      </FormControl>
    </div>
  </div>
  <hr style={{margin: '2rem 0'}} />
  <div className='row'>
    <div className='col-sm-6'>
    <FormControl>
      <FormLabel>Email</FormLabel>
      <TextField
        id="email"
        name="email"
        placeholder="email@forms-surfaces.com"
        variant="outlined"
        value={userInfo?.email || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
    <div className='col-sm-6'>
    <FormControl>
      <FormLabel>Phone Number</FormLabel>
      <TextField
        id="phone"
        name="phone"
        placeholder="Phone Number"
        variant="outlined"
        value={userInfo?.phone || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
  </div>
  <hr style={{margin: '2rem 0'}} />
  <div className='row'>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>City</FormLabel>
      <TextField
        id="city"
        name="city"
        placeholder="City"
        variant="outlined"
        value={userInfo?.city || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>State/Province</FormLabel>
      <TextField
        id="stateProvince"
        name="stateProvince"
        placeholder="State/Province"
        variant="outlined"
        value={userInfo?.stateProvince || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
  </div>
  <div className='row'>
    <div className='col-sm-6'>
      <FormControl>
      <FormLabel>Zip/Postal Code</FormLabel>
      <TextField
        id="postal_code"
        name="postal_code"
        placeholder="Zip/Postal Code"
        variant="outlined"
        value={userInfo?.postal_code || ''}
        onChange={handleUpdate}
        />
        </FormControl>
    </div>
    <div className='col-sm-6'>
      <CountrySelect
      country={userInfo?.country || ''}
      onChange={handleUpdate}
      />
    </div>
  </div>
  </form>
  )
}
