import React from 'react'
import Account from '../components/Account/index.js'
import PrivateRoute from '../components/private-route'

const App = ({data, pageContext}) => {
  return(
    <PrivateRoute path="/account" component={Account}  />
  )
}

export default App
