import React from 'react'
import {
  FormLabel,
  TextField } from '@mui/material'

export default function ChangePassword({handleSubmit, handleUpdate, disableSubmit, handleCancel})  {
  return(
  <form
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
    style={{
      width: '100%'
    }}
  >
    <p>
      Please enter your previous password and your updated password to change your password.
    </p>
    <div className="row">
      <div className="col-sm-12">
        <FormLabel>
          Previous Password
        </FormLabel>
        <TextField
          required
          id="previousPassword"
          name="previousPassword"
          placeholder="Previous Password"
          variant="outlined"
          type="password"
          onChange={handleUpdate}
          sx={{
            width: '100%',
            marginBottom: '.5rem'
          }}
        />
      </div>
      <div className="col-sm-12">
        <FormLabel>
          New Password
        </FormLabel>
        <TextField
          required
          id="newPassword"
          name="newPassword"
          placeholder="New Password"
          variant="outlined"
          type="password"
          onChange={handleUpdate}
          sx={{
            width: '100%'
          }}
        />
      </div>
    </div>
  </form>
  )
}
