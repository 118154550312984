import React, { Component } from 'react'
import Layout from '../layout-mobile'
import {
  Alert,
  Button,
  Snackbar
} from '@mui/material'
import ChangePassword from '../Form/change-password'
import UserInfoForm from '../Form/user-info'
import UserInfoView from '../Form/user-info-view'
import EmailVerifyForm from '../Form/email-verify'
import LoadingBox from '../loading-box'
import { logout, changePassword, updateUserEmail, verifyEmailUpdate } from '../../utils/auth'
import { getUserInfo, updateProfile } from '../../utils/user'
import { navigate } from '../../utils/misc'

class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      panel1: true,
      previousPassword: '',
      newPassword: '',
      errMsg: '',
      successMsg: '',
      warningMsg: '',
      userInfo: null,
      userInfoOld: null,
      viewPasswordChange: false,
      viewUserEdit: false,
      mode: '',
      verificationCode: ''
    }
  }


  componentDidMount(){
    this.handleGetUserInfo()
  }

  handleGetUserInfo = () => {
    this.setState({
      loading: true
    })
    getUserInfo()
    .then(info=>{
      this.setState({
        userInfo: {...info},
        userInfoOld: {...info},
        loading: false
      })
    })
    .catch(err=>{
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    })
  }

  handleLogout = () => {
    logout()
    this.handleGoToAccount()
  }

  handleGoToAccount = () => {
    navigate('/account')
  }


  handlePasswordUpdate = (e) => {
    if(e.target.name === 'previousPassword'){
      this.setState({
        previousPassword: e.target.value
      })
    }
    if(e.target.name === 'newPassword'){
      this.setState({
        newPassword: e.target.value
      })
    }
  }

  handlePasswordSubmit = () => {
    this.setState({
      successMsg: 'Password Updated',
      viewPasswordChange: false
    })
    changePassword(this.state.previousPassword, this.state.newPassword,
      function(err, data){
      if(err){
        this.setState({
          errMsg: err.toString()
        })
      }else{
        this.setState({
          successMsg: 'Password Updated',
          viewPasswordChange: false
        })
      }
    }.bind(this))
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  handleCloseWarning = () => {
    this.setState({
      warningMsg: ''
    })
  }

  handleUserInfoUpdate = (e) => {
    let userInfo = this.state.userInfo
    if(!userInfo.name){
      userInfo.name = {
        first: '',
        last: ''
      }
    }
    if(e.target.name === 'firstName' || e.target.name === 'lastName'){
      if(e.target.name === 'firstName'){
        userInfo.name.first = e.target.value
      }else{
        userInfo.name.last = e.target.value
      }
    }else{
      userInfo[e.target.name] = e.target.value
    }
    this.setState({
      userInfo: userInfo
    })
  }

  handleSubmitProfile = (e) => {
    e.preventDefault()
    if(this.state.userInfo.email.trim() !== this.state.userInfoOld.email.trim()){
      //Only update email if user confirms their address
      updateUserEmail(this.state.userInfo.email, function(err, data){
        if(err){
          this.setState({
            errMsg: err.message
          })
        }else{
          this.setState({
            warningMsg: 'Please verify your new email address at ' + this.state.userInfo.email,
            mode: 'email-verify'
          })
        }
      }.bind(this))
    }else{
      updateProfile(this.state.userInfo)
      .then((data)=>{
        this.setState({
          successMsg: 'Profile Info Updated',
          mode: '',
          viewUserEdit: false
        })
        this.handleGetUserInfo()
      })
      .catch((err)=>{
        this.setState({
          errMsg: err.toString()
        })
      })
    }
  }

  handleEmailVerify = (e) => {
    
    verifyEmailUpdate(this.state.verificationCode, function(err, data){
      if(err){
        this.setState({
          errMsg: err.toString()
        })
      }else{
        this.setState({
          successMsg: 'Profile Info Updated',
          mode: ''
        })
        updateProfile(this.state.userInfo)
        .then((data)=>{
          this.setState({
            successMsg: 'Profile Info Updated',
            mode: '',
            viewUserEdit: false
          })
          this.handleGetUserInfo()
        })
        .catch((err)=>{
          this.setState({
            errMsg: err.toString()
          })
        })
      }
    }.bind(this))
  }

  handleCodeUpdate = (e) => {
    this.setState({
      verificationCode: e.target.value
    })
  }

  render() {

    let location = []
    let cityState = []
    if(this.state.userInfo?.city){
      cityState.push(this.state.userInfo?.city)
    }
    if(this.state.userInfo?.stateProvince){
      cityState.push(this.state.userInfo?.stateProvince)
    }
    if(cityState.length > 0){
      location.push(cityState.join(', '))
    }
    if(this.state.userInfo?.postal_code){
      location.push(this.state.userInfo?.postal_code)
    }
    if(this.state.userInfo?.country){
      location.push(this.state.userInfo?.country)
    }
    let job = []
    if(this.state.userInfo?.job_title){
      job.push(this.state.userInfo.job_title)
    }
    if(this.state.userInfo?.company){
      job.push(this.state.userInfo.company)
    }
    let toolFunctions = <>
      <Button variant="contained" onClick={()=>{this.setState({mode: 'passwordReset'})}}>Change Password</Button>
      <Button variant="contained" onClick={()=>{this.setState({mode: 'edit'})}}>Edit</Button>
      <Button variant="contained" onClick={this.handleLogout}>Sign Out</Button>
    </>
    if(this.state.mode === 'edit'){
      toolFunctions = <>
      <Button onClick={()=>{this.setState({mode: ''})}}>Cancel</Button>
      <Button variant="contained" onClick={this.handleSubmitProfile}>Save</Button>
      </>
    }
    if(this.state.mode === 'passwordReset'){
      toolFunctions = <>
      <Button onClick={()=>{this.setState({mode: ''})}}>Cancel</Button>
      <Button variant="contained" onClick={this.handlePasswordSubmit}>Save</Button>
      </>
    }
    if(this.state.mode === 'email-verify'){
      toolFunctions = <>
      <Button onClick={()=>{this.setState({userInfo: this.state.userInfoOld, mode: ''})}}>Cancel</Button>
      <Button variant="contained" onClick={this.handleEmailVerify} disabled={this.state.verificationCode === '' }>Verify</Button>
      </>
    }
    return(
      <Layout titleText="Account" showMenu={true} path={this.props.path} toolFunctions={toolFunctions}>
      {this.state.loading &&
        <LoadingBox />
      }
      {this.state.mode === 'passwordReset' &&
            <div className='password-change-form' style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              maxWidth: '600px',
              flexGrow: 1
            }}>
              <ChangePassword
              handleSubmit={this.handlePasswordSubmit}
              handleUpdate={this.handlePasswordUpdate}
              />
            </div>
      }
      {this.state.mode === 'edit' &&
            <div className='user-edit-form' style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              maxWidth: '800px',
              flexGrow: 1
            }}>
            <UserInfoForm
            userInfo={this.state.userInfo}
            handleSubmit={this.handleSubmitProfile}
            handleUpdate={this.handleUserInfoUpdate}
            handleCancel={(e)=>{
              this.setState({
                viewUserEdit: false
              })
            }}
            />
          </div>
      }
      
      {this.state.mode === 'email-verify' &&
          <div className='email-verify-form'>
              <EmailVerifyForm
              username={this.state.userInfo?.email}
              handleUpdate={this.handleCodeUpdate}
              />
          </div>
      }
      {this.state.mode === '' && 
              <div className='user-info-view'>
                <UserInfoView 
                  userInfo={this.state.userInfo}
                />
              </div>
      }
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.warningMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseWarning}>
        <Alert elevation={6} variant="filled" severity="warning" onClose={this.handleCloseWarning}>
          {(this.state.warningMsg ? this.state.warningMsg : '')}
        </Alert>
      </Snackbar>
      </Layout>
    )
  }
}

export default Account
